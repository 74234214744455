#page-calculateMediaPlan {
    padding: 25px 0 50px;
    background: rgba(242, 242, 242, 1);

    @extend %MediaPlanGridTitle;

    .filter {
        background-color: $white-color;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0666666666666667);
        border-radius: 5px;
        padding: 25px 25px 25px;

        .input-grid {
            padding-bottom: 25px;
        }

        .MuiTextField-root .MuiFormLabel-root,
        .MuiInputLabel-formControl {
            color: #D7D7D7!important;
        }

        .MuiInputAdornment-root {
            color: #AAAAAA;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 1px solid #AAAAAA;
        }
        .MuiInput-underline.Mui-disabled:before {
            border-bottom-style: solid;
        }

        .autocomplete-box {
            .label {
                font-weight: 700;
                font-style: normal;
                font-size: 14px;
                color: rgb(51, 51, 51);
                margin-bottom: 10px;
                >div {
                    position: relative;
                    display: inline-block;

                    span {
                        font-size: 18px;
                        color: #ec6518;
                        position: absolute;
                        top: -5px;
                        right: -15px;
                    }
                }
            }
            .MuiInputBase-input::placeholder {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: #AAAAAA;
            }
            .MuiIconButton-label {
                color: $primary-color;
            }

            .selected-values {
                margin: 25px 0 0 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    border: 1px solid $primary-color;
                    background-color: rgba($primary-color, .1);
                    border-radius: $base-border-radius;
                    margin-bottom: 10px;

                    .name {
                        width: calc(100% - 37px);
                        padding: 12px;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 14px;
                    }
                    .control {
                        width: 37px;
                        height: 30px;
                        overflow: hidden;
                    }
                }

                li:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .filter-apply {
            width: 100%;
            height: 50px;
            line-height: 50px;
            outline: 0;
            font-weight: 700;
            font-style: normal;
            font-size: 16px;
            letter-spacing: 0.8px;
            color: $white-color;
            background: rgba(236, 101, 24, 1);
            border: 0;
            border-radius: $base-border-radius;

            &:disabled {
                background: rgba(236, 101, 24, 0.5);
                cursor: auto;
            }
            &:not([disabled]):hover {
                background: rgb(249, 74, 13);
                cursor: pointer;
            }
        }
    }

    @extend %MediaPlanTotalBox;
}
.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: rgba($primary-color, .1) !important;
    color: $default-color;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: rgba($primary-color, .3) !important;
    color: $default-color!important;
}
.Selectable {
    .DayPicker-Day {
        width: 40px;
        height: 40px;
        padding: 0;
        color: $default-color;
    }
    .DayPicker-Day,
    .DayPicker-wrapper,
    .DayPicker-Weekdays,
    .DayPicker-Body,
    .DayPicker-Month,
    .DayPicker-Months {
        outline: 0!important;
    }
    .DayPicker-wrapper {
        padding-bottom: 0;
    }
    .DayPicker-NavButton {
        outline: 0;
    }
    .DayPicker-NavButton--next {
        background-image: url("../images/plus.svg");
    }
    .DayPicker-NavButton--prev {
        background-image: url("../images/minus.svg");
    }
}
.DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
        &:hover {
            background-color: rgba($primary-color, .1)!important;
        }
    }
}

#media-plan-table {
    position: relative;
    padding: 50px;
    >div {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0666667);
        overflow: hidden;
        border-radius: 5px;
        position: relative;

        .loading {
            position: absolute;
            z-index: 9999;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            background: rgba($white-color, .5);
            padding-top: 5%;
        }
    }

    @extend %VirtualizedTable;
}
.dialog-filter {
    .MuiDialog-paperWidthSm {
        width: 300px;
    }
    .MuiDialogTitle-root {
        padding-bottom: 0;
    }
    .MuiDialogContent-root {
        padding: 0;
    }
    .MuiDialogActions-root.MuiDialogActions-spacing {
        padding-top: 0;
    }
    .MuiTypography-body2 {
        text-transform: capitalize;
    }
    .MuiListItemIcon-root {
        min-width: 30px;
    }
    .MuiListItem-gutters {
        padding-top: 0;
        padding-bottom: 0;
    }
}
