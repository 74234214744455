@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import "~normalize-scss";
@import "~pure-react-carousel/dist/react-carousel.es.css";
@import "~react-day-picker/lib/style.css";
@import '~react-virtualized/styles.css';
@import '~react-toastify/dist/ReactToastify.css';
@import "./_variables";
@import "./components/_section";
@import "./header";
@import "./components/homeSlider";
@import "./components/advertisingCatalog";
@import "./components/workInfo";
@import "./components/ourAdvantages";
@import "./components/figures";
@import "./components/reviews";
@import "./components/footer";
@import "./components/pageCatalog";
@import "./components/contacts";
@import "./components/authorization";
@import "./components/cabinet";
@import "./components/calculateMediaPlan";
@import "./components/tableDialog";
@import "./components/catalogItem";
@import "./components/cases";
@import "./components/page";

html, body {
    font-family: 'Roboto', sans-serif!important;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-width: $min-site-width;

    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: $default-color;
}
.app-container {
    padding-top: $header-height;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.cursor-pointer {
    cursor: pointer;
}
.mb-0 {
    margin-bottom: 0!important;
}

.MuiTextField-root {
    .MuiFormLabel-root {
        color: #AAAAAA!important;
    }

    .MuiInput-underline:before {
        border-color: #AAAAAA!important;
        transition: .2s;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid #AAAAAA;
        transition: .2s;
    }
}
.MuiDialogContent-root:first-child {
    padding: 8px 24px!important;
}

.dialog-grid {
    display: flex;
    align-items: center;

    .images-grid,
    .description-grid {
        display: flex;
    }

    .images-grid {
        >div {
            width: 250px;
            height: 250px;
            display: block;
            background-image: url('./images/feedback.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .description-grid {
        padding: 10px 15px 10px 25px;

        .title {
            font-weight: 700;
            font-style: normal;
            font-size: 25px;
            letter-spacing: 0.8px;
            color: $primary-color;
        }

        .message {
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: #7F7F7F;

            a {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: #7F7F7F;
            }
        }
    }
}
.Toastify__progress-bar--default {
    background: $primary-color!important;
}
.Toastify__toast--default {
    color: $default-color!important;
}
.loading-backdrop {
    background-color: rgba(255, 255, 255, .5)!important;
    z-index: 9999999!important;
}

.react-images__blanket {
   z-index: 99999!important;
}
.react-images__positioner {
   z-index: 999999!important;
}
.editMediaPlan-content {
    min-height: 500px;
    text-align: center;
    padding: 50px;
}
