#figures {
    @extend %section;
    background: $default-color;
    padding-bottom: 100px;

    .title-grid {
        margin-bottom: 100px;
        .title, .signature {
            color: $white-color;
            white-space: pre-wrap;
        }
    }

    .card {
        text-align: center;

        .amount {
            color: $primary-color;
            font-weight: 500;
            font-style: normal;
            font-size: 90px;
        }

        .description {
            color: $white-color;
            font-weight: 400;
            font-style: normal;
            font-size: 21px;
        }
    }
}
