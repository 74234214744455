#contacts {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 230, 201, 1) 100%);
    padding: 50px 0 150px;

    .form-header {
        display: flex;
        margin-bottom: 30px;

        .email,
        .phone {
            display: flex;
            padding: 0;
            span {
                // display: inline-block;
                // width: 100%;
                display: block;
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                white-space: pre-wrap;
            }
        }

        .phone {
            border-right: 1px solid $primary-color;
            // padding-right: 40px;
            padding-right: 25px;

            a {
                font-weight: 400;
                font-style: normal;
                font-size: 45px;
                color: $primary-color;
                text-decoration: none;
            }
        }

        .email {
            // padding-left: 40px;
            > div {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
            }
            padding-left: 30px;
            a {
                font-weight: 400;
                font-style: normal;
                font-size: 40px;
                color: $primary-color;
                text-decoration: none;
            }
        }
    }

    form {
        padding: 20px 25px;
        background: $white-color;
        border-radius: $base-border-radius;
        box-shadow: rgba(0, 0, 0, 0.067) 3px 3px 3px;

        .welcome-text {
            font-weight: 400;
            font-style: normal;
            font-size: 21px;
            line-height: 27px;
        }

        .form-content {
            margin: 1rem 0;
        }

        .grid-recaptcha {
            width: 100%;
            height: 100px;
            display: block;
        }

        button {
            cursor: pointer;
            background: $primary-color;
            color: $white-color;
            border: 1px solid $primary-color;
            transition: .2s;
            box-shadow: rgba(0, 0, 0, 0.067) 3px 3px 3px;
            font-weight: 700;
            font-style: normal;
            font-size: 16px;
            letter-spacing: 0.8px;
            outline: 0;
            padding: 10px 30px;
            border-radius: $base-border-radius;
            transition: .2s;

            &:hover {
                transition: .2s;
                background: $white-color;
                color: $primary-color;
            }
        }
    }

    .bg-contacts {
        background-image: url("../images/contacts.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: flex;
        width: 100%;
        height: 100%;
    }
}
