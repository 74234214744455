%title {
    font-weight: 700;
    font-style: normal;
    font-size: 50px;
    color: $default-color;
    text-align: center;
}

%buttonCalculateMediaPlan {
    display: inline-block;
    background: rgba(236, 101, 24, 1);
    color: $white-color;
    font-weight: 700;
    font-style: normal;
    font-size: 21px;
    transition: .2s;
    padding: 15px 20px;
    border-radius: $base-border-radius;
    text-decoration: none;

    &:hover {
        background: rgba(249, 74, 13, 1);
        transition: .2s;
    }
}

%section {
    padding: 50px 0;
    background: rgba(237, 239, 242, 0.4);

    .title {
        @extend %title;
        padding-bottom: 50px;
    }

    .title-grid {
        margin-bottom: 50px;
        .title {
            @extend %title;
            padding-bottom: 25px;
        }
        .signature {
            font-weight: 400;
            font-style: normal;
            font-size: 21px;
            color: $default-color;
            text-align: center;
            line-height: 23px;
        }
    }
}

%VirtualizedTable {
    .ReactVirtualized__Grid__innerScrollContainer,
    .ReactVirtualized__Grid {
        outline: 0;
    }

    .GridRow {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        position: relative;
        z-index: 9;
    }

    .GridColumn {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        &:not(.p-0) {
            padding: 5px 10px;
        }
    }

    .GridColumnCheckbox {
        width: 100%;
        height: 100%;
        display: flex;
        background-image: url("../images/arrow_down.svg");
        background-repeat: no-repeat;
        background-position: center;
    }

    .table-header {
        background: rgba(41, 67, 78, 0.9);
        color: $white-color;

        .selected-field {
            position: relative;
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;

            >span {
                width: calc(100% - 18px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }

            .filter-button {
                width: 18px;
                height: 100%;
                display: flex;
                position: absolute;
                right: 0;
            }
            .filter-button::after,
            .filter-button::before {
                content: "";
                width: 18px;
                height: 100%;
                display: flex;
                position: absolute;
                right: 0;
                background-image: url("../images/filter_table.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transition: .2s;
                opacity: 1;
            }
            .filter-button::before {
                opacity: 0;
                background-image: url("../images/filter_table_hover.svg");
            }

            .filter-button:hover {
                &::after {
                    opacity: 0;
                    transition: .2s;
                }
                &::before {
                    opacity: 1;
                    transition: .2s;
                }
            }
        }

        .sorteble-field {
            position: relative;
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;

            > span {
                width: calc(100% - 18px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }

            &::before {
                content: "";
                width: 18px;
                height: 100%;
                display: flex;
                position: absolute;
                right: 0;
                background-image: url("../images/arrow_down.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transition: .2s;
                opacity: 0;
            }

            &.ASC {
                &::before {
                    transform: rotate(0deg);
                    transition: .2s;
                    opacity: 1;
                }
            }

            &.DESC {
                &::before {
                    transform: rotate(180deg);
                    transition: .2s;
                    opacity: 1;
                }
            }
        }

        .sorteble-and-selected-field {
            .sortfield {
                width: calc(100% - 18px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
                position: relative;
            }

            .sortfield {
                &::before {
                    content: "";
                    width: 18px;
                    height: 100%;
                    display: flex;
                    position: absolute;
                    right: 15px;
                    background-image: url("../images/arrow_down.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: .2s;
                    opacity: 0;
                }
    
                &.ASC {
                    &::before {
                        transform: rotate(0deg);
                        transition: .2s;
                        opacity: 1;
                    }
                }
    
                &.DESC {
                    &::before {
                        transform: rotate(180deg);
                        transition: .2s;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .table-body {
        background: $white-color;
        .GridColumn {
            transition: .2s;

            &:not(.border-bottom-0) {
                border-bottom: 1px solid rgba(154, 154, 154, .3);
            }

            &.selected {
                background: rgba($primary-color, .15);
                transition: .2s;
            }

            span.whiteSpaceNowrap,
            span:not(.tooltip) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
                cursor: default;
            }
            .tooltip {
                 word-break: break-all;
            }

            &.button-modifyDescription {
                &.therePictures {
                    cursor: pointer;
                    span {
                        text-decoration: underline;
                    }
                }
            }

            .input-number {
                display: flex;
                height: 30px;
                overflow: hidden;

                button, input {
                    float: left;
                }

                input {
                    width: calc(100% - 60px);
                    height: 30px;
                    background-color: rgba(255, 255, 255, 0);
                    box-sizing: border-box;
                    border: 1px solid rgba(215, 215, 215, 1);
                    border-radius: 5px;
                    text-align: center;
                }
                button {
                    width: 30px;
                    height: 30px;
                    border: 0;
                    background: transparent;
                    text-align: center;
                    outline: 0;
                    cursor: pointer;
                    transition: .2s;
                    background-position: center;
                    background-repeat: no-repeat;

                    &.minus {
                        background-image: url("../images/minus.svg")
                    }
                    &.plus {
                        background-image: url("../images/plus.svg")
                    }
                }
            }

            .fire {
                width: 40px;
                height: 40px;
                display: block;
                background-image: url("../images/fire.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin: 4px 0;
            }
        }
        &.border-bottom-0 {
            border-bottom: 0;
        }
    }

    .GridHeader {
        position: relative;
        &:not(.no-border)::after {
            content: "";
            width: 2px;
            height: 80%;
            display: block;
            background: $white-color;
            position: absolute;
            left: 0;
            top: 10%;
        }
    }
}

%MediaPlanGridTitle {
    .grid-title {
        padding-bottom: 25px;

        .title {
            font-weight: 700;
            font-style: normal;
            font-size: 21px;
            letter-spacing: 0.8px;
            color: $default-color;
        }

        .link-to-instruction {
            text-align: right;

            a {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: $default-color;
                transition: .2s;

                &:hover {
                    transition: .2s;
                    color: $primary-color;
                }
            }
        }
    }
}

%MediaPlanTotalBox {
    .total-box {
        background: $white-color;
        .table-last-row {
            border-top: 1px solid rgba(154, 154, 154, 0.3);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 20px 40px;
            >div {
                display: flex;
                align-items: center;

                div:first-child {
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    padding-right: 20px;
                }

                div:last-child {
                    font-weight: 700;
                    font-style: normal;
                    font-size: 21px;
                    color: #EC6518;
                }
            }
        }
        .table-footer {
            display: flex;
            background: $default-color;
            align-items: center;
            padding: 20px 50px;

            .selected-items,
            .budget,
            .create {
                width: 33%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white-color;
                font-size: 16px;
                font-weight: 400;

                span {
                    padding-left: 10px;
                    font-weight: 700;
                    font-size: 21px;
                }

                button {
                    width: 260px;
                    height: 50px;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 16px;
                    color: $white-color;
                    border: 0;
                    background: rgba(236, 101, 24, 1);
                    border-radius: $base-border-radius;
                    transition: .2s;
                    cursor: pointer;
                    outline: 0;

                    &:hover {
                        background: rgba(249, 74, 13, 1);
                        transition: .2s;
                    }

                    &:disabled {
                        background: rgba(236, 101, 24, .5);
                        cursor: auto;
                    }
                }
            }

            .selected-items {
                justify-content: flex-start;
            }
            .create {
                justify-content: flex-end;
            }
        }
    }
}
