#home-slider {
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 230, 201, 1) 100%);
    display: flex;
    padding: 60px 0;

    .description {
        .title {
            font-size: 65px;
            letter-spacing: -1px;
            line-height: 65px;
            font-style: normal;
            color: $default-color;
            font-weight: 300;

            &.primary {
                color: $primary-color;
                font-weight: 700;
            }
        }

        .signature {
            margin-top: 25px;
            font-weight: 400;
            font-style: normal;
            font-size: 21px;
            line-height: 30px;
            color: $default-color;
        }

        .button-content {
            margin-top: 60px;

            a {
                @extend %buttonCalculateMediaPlan;
            }
        }
    }

    .image {
        img {
            width: 100%;
        }
    }
}
