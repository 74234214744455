#page {
    min-height: 500px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 230, 201, 1) 100%);
    padding: 25px 0 100px;

    @extend %MediaPlanGridTitle;
    @extend %MediaPlanTotalBox;

    .loading {
        padding: 100px;
        text-align: center;
    }

    .grid-description {
        .description {
            color: #29434E;
            font-size: 14px;
            margin-bottom: 50px;
        }
    }
}