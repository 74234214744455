%TableDialogButton {
    color: $white-color;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border-radius: $base-border-radius;
    outline: 0;
    transition: .2s;

    svg {
        margin-right: 10px;
    }

    &:hover {
        cursor: pointer;
        transition: .2s;
    }
}

.table-dialog {
    .MuiDialog-paperFullWidth {
        min-width: calc(100% - 64px);
    }

    .MuiTextField-root .MuiFormLabel-root,
    .MuiInputLabel-formControl {
        color: #D7D7D7!important;
    }

    .MuiInputAdornment-root {
        color: #AAAAAA;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid #AAAAAA;
    }
    .MuiInput-underline.Mui-disabled:before {
        border-bottom-style: solid;
    }

    .table-dialog-title {
        font-size: 21px;
        font-weight: 400;
        color: $default-color;

        .name {
            text-align: left;
            span {
                font-weight: 700;
                color: $primary-color;
            }
        }
        .date-range {
            text-align: right;
        }
    }

    .undo-button {
        float: left;
        background: $primary-color;
        border: 1px solid $primary-color;
        @extend %TableDialogButton;
    }

    .redo-button {
        float: left;
        margin-left: 15px;
        background: $primary-color;
        border: 1px solid $primary-color;
        text-decoration: none;
        @extend %TableDialogButton;

        svg {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .DialogContent-footer {
        display: flex;
        align-items: center;

        .final-budget {
            text-align: right;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;

            span {
                margin-left: 10px;
                font-weight: 700;
                font-style: normal;
                font-size: 21px;
                color: #EC6518;
            }
        }
    }

    .MuiDialogActions-root {
        padding: 24px;
        background: $default-color;

        .DialogContent-footer {
            .MuiGrid-item:first-child {
                button {
                    @extend %TableDialogButton;

                    float: left;
                    margin-right: 15px;
                    background: $default-color;
                    border: 1px solid $white-color;

                    &:hover {
                        background: rgba($white-color, .3);
                    }
                }
            }
            .MuiGrid-item:last-child {
                button {
                    @extend %TableDialogButton;

                    padding: 15px 30px;
                    float: right;
                    border: 1px solid #EC6518;
                    background: #EC6518;

                    font-weight: 700;
                    font-style: normal;
                    font-size: 16px;
                    letter-spacing: 0.8px;

                    &:hover {
                        background: rgb(249, 74, 13);
                    }
                }
            }
        }
    }

    @extend %VirtualizedTable;
    .table-body {
        border-bottom: 1px solid rgba(154, 154, 154, 0.3);
        .GridColumn {
            &.selected {
                background: $white-color;
            }
        }
    }
}
