.cases-page {
    .cases-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        padding: 15px 25px;
        margin-bottom: 25px;

        .logo-container {
            img {
                width: 250px;
                display: block;
                margin-bottom: 25px;
            }
        }

        .main-description {
            font-weight: 400;
            font-style: normal;
            font-size: 20px;
            color: #29434E;
            line-height: 23px;
            margin-bottom: 25px;
        }

        .info-block {
            .number {
                font-weight: 700;
                font-style: normal;
                font-size: 45px;
                color: #4caf50;
                margin-bottom: 5px;
            }
            .name {
                font-weight: 300;
                font-style: normal;
                font-size: 18px;
                color: #29434E;
                margin-bottom: 25px;
            }
            .description {
                font-weight: 300;
                font-size: 14px;
            }
        }

        .images-top {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .main {
                width: 100%;
                height: calc((100% / 2));
                background-color: #f4f5f7;
                display: flex;
                margin-bottom: 5px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .additional {
                width: 100%;
                height: calc((100% / 2));
                display: flex;
                margin-top: 5px;

                .left,
                .right {
                    height: 100%;
                    width: calc((100% / 2));
                    background-color: #f4f5f7;
                    display: flex;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                .left {
                    margin-right: 5px;
                }
                .right {
                    margin-left: 5px;
                }
            }
        }

        .images-left {
            width: 100%;
            height: 100%;
            display: flex;

            .main,
            .additional {
                height: 100%;
                width: calc((100% / 2));
                display: flex;
            }

            .main {
                background-color: #f4f5f7;
                margin-right: 5px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .additional {
                margin-left: 5px;
                flex-direction: column;

                .left,
                .right {
                    height: calc((100% / 2));
                    width: 100%;
                    background-color: #f4f5f7;
                    display: flex;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                .left {
                    margin-bottom: 5px;
                }
                .right {
                    margin-top: 5px;
                }
            }
        }
    }
}