#header {
    width: 100%;
    min-width: $min-site-width;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 999;

    height: $header-height;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.0470588235294118);

    ul {
        padding: 0;
        margin: 0;
    }

    .grid-container {
        display: flex;

        .grid-logo {
            display: flex;
            height: $header-height;
            align-items: center;

            margin-right: 30px;

            div {
                width: 190px;
                height: 30px;
                display: flex;
                background: url("./images/logo.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .grid-to-contact-us {
            display: flex;
            align-items: center;

            div {
                width: 100%;
            }

            a {
                display: inline-block;
                position: relative;
                padding-left: 25px;

                width: 100%;
                font-size: 12px;

                color: #29434E;
                text-decoration: none;
                cursor: pointer;
                text-decoration: underline;
                margin-bottom: 5px;
                transition: .2s;

                svg {
                    position: absolute;
                    left: 0;
                    top: -1px;
                    color: #4caf50;
                    font-size: 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    color: #4caf50;
                    transition: .2s;
                }
            }
        }

        .grid-personalArea {
            display: flex;
            height: $header-height;
            align-items: center;
            width: 120px;

            a,
            button {
                outline: 0;
                border: 1px solid $primary-color;
                background: $white-color;
                color: $primary-color;
                border-radius: $base-border-radius;
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                width: 100%;
                padding: 7px 10px;
                cursor: pointer;
                transition: .2s;

                &:hover {
                    transition: .2s;
                    background: $primary-color;
                    color: $white-color;
                }
            }

            a {
                text-align: center;
                text-decoration: none;
            }
        }

        .grid-menu {
            /*
                $sum = width.grid-personalArea + width.grid-logo
                clalc (100% - $sum)
            */
            width: calc(100% - 310px);
            ul {
                display: flex;
                height: $header-height;
                // justify-content: flex-end;
                justify-content: flex-start;

                li {
                    display: flex;
                    align-items: center;
                    list-style-type: none;
                    padding: 0;
                    margin: 0 25px 0 0;
                    position: relative;

                    &:last-child {
                        margin: 0;
                    }

                    a {
                        color: $default-color;
                        text-decoration: none;
                        transition: .2s;
                        font-size: 15px;

                        &:hover {
                            transition: .2s;
                            color: $primary-color;
                        }

                        &::after {
                            content: "";
                            width: 100%;
                            position: absolute;
                            height: 0;
                            display: block;
                            background: $primary-color;
                            left: 0;
                            bottom: 0;
                            transition: .2s;
                        }

                        &.selected {
                            &::after {
                                height: 5px;
                                transition: .2s;
                            }
                        }
                    }
                }
            }
        }
    }
}
