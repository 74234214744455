#work-info {
    @extend %section;
        background: linear-gradient(180deg,
        rgba(219, 239, 220, 1) 52%,
        rgba(236, 246, 237, 1) 85%,
        rgba(255, 255, 255, 1) 93%
        );

    .row-grid {
        width: 100%;
        padding-bottom: 100px;

        .image {
            width: 95%;
            float: left;
        }

        &.right .image {
            float: right;
        }

        .description-grid {
            .number {
                position: relative;
                width: 110px;
                height: 110px;

                .circle {
                    left: calc(110px / -3);
                    top: 0;
                    position: absolute;

                    display: block;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 90px;
                    color: $primary-color;
                    z-index: 1;
                    background-color: rgba(255, 255, 255, 0.43921568627451);
                    line-height: 130px;
                    border-radius: 100%;
                    width: 130px;
                    height: 130px;
                    text-align: center;
                }
            }
            .name {
                font-weight: 700;
                font-style: normal;
                font-size: 30px;
                color: $default-color;
                line-height: 35px;
                z-index: 1;
                position: relative;
                padding-bottom: 15px
            }
            .signature {
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                color: $default-color;
                line-height: 25px;
                z-index: 1;
                position: relative;
            }
        }
    }

    .button-content {
        margin: 0 0 50px;
        text-align: center;

        a {
            @extend %buttonCalculateMediaPlan;
        }
    }
}
