#cabinet {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 230, 201, 1) 100%);
    padding: 30px 0 150px;

    .title {
        text-align: left;
        font-weight: 700;
        font-style: normal;
        font-size: 21px;
        letter-spacing: 0.8px;
        color: $default-color;
        padding-bottom: 30px;
    }

    .grid-item {
        background-color: $white-color;
        border-radius: $base-border-radius;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0666666666666667);
        padding: 25px;
        height: calc(100% - 50px);

        .grid-title {
            font-weight: 700;
            font-style: normal;
            font-size: 21px;
            letter-spacing: 0.8px;
            color: $primary-color;
        }

        &.media-plans {
            .grid-title {
                color: #EC6518;
            }
        }
    }

    .media-plans-content {
        margin-top: 25px;

        table {
            width: 100%;
            border-spacing: 0;

            thead {
                background: $default-color;

                th {
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    color: $white-color;
                    text-align: left;
                    height: 50px;
                    padding: 0 20px;
                    position: relative;

                    div {

                        &:after {
                            content: "";
                            width: 1px;
                            height: 40px;
                            background: $white-color;
                            position: absolute;
                            right: 0;
                            top: 5px;
                            display: block;
                        }

                        small {
                            display: block;
                            color: #667E88;
                            text-overflow: ellipsis; //образка длинных блоков с текстом
                            overflow: hidden; //прятать выходящий за пределы текст
                            width: 180px; //ширина блока с текстом
                            white-space: nowrap; //запрет на перенос текста
                        }

                        .search-btn,
                        .sort-arrow {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: .2s;
                            transform: rotate(0deg);

                            &.desc {
                                transform: rotate(180deg);
                                transition: .2s;
                            }
                        }
                    }
                }
            }

            tbody {
                td {
                    height: 50px;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    text-align: left;
                    color: $default-color;
                    padding: 0 20px;
                    border-bottom: 1px solid #d7d7d7;

                    button.link {
                        background: transparent;
                        outline: 0;
                        border: 0;
                        padding: 0;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 14px;
                        text-align: left;

                        &:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }

                td.actions {
                    width: 100px;
                }
            }
        }

        .pagination {
            margin-top: 25px;

            text-align: center;
            >div {
                display: inline-block;

                .MuiPaginationItem-textPrimary.Mui-selected {
                    color: $white-color;
                }
            }
        }
    }

    .form-content {
        margin-top: 25px;
        position: relative;

        &.enable {
            height: calc(100% - 55px);
            position: relative;

            .form-fields {
                height: calc(100% - 100px);
                padding-bottom: 100px;
            }
            .form-control {
                position: absolute;
                bottom: 0;
            }
        }

        .form-field {
            padding: 10px 0;

            label {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: $default-color;

                &.disabled {
                    opacity: .5;
                    transition: .2s;
                }
            }

            span {
                padding: 0 15px;
                border: 1px solid $white-color;
                width: calc(100% - 30px);
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                color: $default-color;
                text-align: left;
                height: 40px;
                line-height: 40px;
                display: block;
            }

            input {
                padding: 0 15px;
                width: calc(100% - 30px);
                border: 1px solid $default-color;
                border-radius: $base-border-radius;
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                color: $default-color;
                text-align: left;
                height: 40px;

                &:focus {
                    outline-color: $primary-color;
                }

                &:disabled {
                    opacity: .5;
                    transition: .2s;
                }
            }
        }

        .form-control {
            padding-top: 35px;
        }

        .submit-form,
        .change-form {
            cursor: pointer;
            outline: 0;
            width: 100%;
            height: 40px;
            background: inherit;
            background-color: $white-color;
            border: 1px solid $primary-color;
            border-radius: $base-border-radius;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.117647058823529);
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            letter-spacing: 0.8px;
            color: $primary-color;
            transition: .2s;

            &:hover {
                transition: .2s;
                background-color: $primary-color;
                color: $white-color;
            }

            &:disabled {
                opacity: .5;
                transition: .2s;
            }
        }

        .submit-form {
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: $white-color;

            &:hover {
                transition: .2s;
                background-color: $white-color;
                color: $primary-color;
            }
        }
    }
}
