#reviews {
    @extend %section;
    background: linear-gradient(180deg,
        rgba(219, 239, 220, 1) 52%,
        rgba(236, 246, 237, 1) 85%,
        rgba(255, 255, 255, 1) 93%);
    padding: 60px 0;

    .title {
        padding-bottom: 60px;
    }

    .grid-card {
        margin: 0 40px;
        position: relative;

        .card {
            margin: 0 15px;
            >div {
                padding: 20px 30px;
                background: $white-color;
                border-radius: 5px;
                box-shadow: rgba(150, 150, 150, 0.1) 0px 0px 20px 0px;
                transition: background .2s;
                height: 90%;

                .image {
                    padding-bottom: 20px;

                    img {
                        height: 115px;
                    }
                }

                .description {
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    color: $default-color;
                    line-height: 25px;
                }
            }
        }
        .button-back, .button-next {
            width: 50px;
            height: 50px;
            background: $white-color;
            border-radius: 50%;
            border: 0;
            position: absolute;
            top: calc((100% - 50px) / 2);
            outline: 0;
            background-repeat: no-repeat;
            background-position: center;
            transition: .2s;
            box-shadow: rgba(150, 150, 150, 0.1) 0px 0px 0px 0px;

            &:hover {
                transition: .2s;
                box-shadow: rgba(0, 0, 0, 0.2) 9px 9px 9px -5px;
            }
        }
        .button-back {
            left: -50px;
            background-image: url('../images/arrow_left.svg');
            background-position: 45% center;
        }

        .button-next {
            right: -50px;
            background-image: url('../images/arrow_right.svg');
            background-position: 55% center;
        }
    }

    .button-content {
        padding: 70px 0 10px;
        text-align: center;

        a {
            @extend %buttonCalculateMediaPlan;
        }
    }
}
