.dialog-authorization {

    .dialog-content {
        width: 660px;
        overflow: hidden;
        >div {
            padding: 40px 70px;
        }
    }

    .enter-form-title {
        font-weight: 400;
        font-style: normal;
        font-size: 21px;
        color: $default-color;
        line-height: 27px;
        padding-bottom: 30px;
    }

    .grid-form-change {

        button {
            width: 100%;
            height: 40px;
            background: inherit;
            box-sizing: border-box;
            border-radius: $base-border-radius;
            box-shadow: none;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            outline: 0;
            border: 1px solid $default-color;
            color: $default-color;
            background-color: $white-color;
            cursor: pointer;
            transition: .2s;

            &:hover {
                background-color: rgba(170, 170, 170, 1);
                transition: .2s;
            }

            &.active {
                background-color: $default-color;
                border: 1px solid rgba(215, 215, 215, 1);
                color: $white-color;
                transition: .2s;
            }
        }
    }

    form {
        padding-top: 30px;
    }

    .form-field {
        padding: 10px 0;

        label {
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: $default-color;
        }

        input {
            padding: 0 15px;
            width: calc(100% - 30px);
            border: 1px solid $default-color;
            border-radius: $base-border-radius;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            color: $default-color;
            text-align: left;
            height: 40px;

            &:focus {
                outline-color: $primary-color;
            }
        }
    }

    .form-control {
        padding: 30px 0 0;

        button {
            cursor: pointer;
            outline: 0;
        }

        button[type="button"] {
            border: none;
            background: transparent;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: $default-color;
        }

        button[type="submit"] {
            width: 100%;
            height: 40px;
            background: inherit;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            border-radius: $base-border-radius;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.117647058823529);
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            letter-spacing: 0.8px;
            color: $white-color;
            transition: .2s;

            &:hover {
                transition: .2s;
                background-color: $white-color;
                color: $primary-color;
            }

            &:disabled {
                transition: .2s;
                border-color: rgba(170, 170, 170, 1);
                background-color: rgba(170, 170, 170, 1);
                color: $default-color;
                cursor: default;
            }
        }

        .MuiFormControlLabel-root {
            .label-checkbox {
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                color: $default-color;
            }
        }
    }
}
