#page-catalog-sale,
#page-catalog {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(200, 230, 201, 1) 100%);
    padding: 25px 0 150px;

    #advertising-catalog {
        background: none;
        padding: 0;

        .title {
            text-align: left;
            font-style: normal;
            font-size: 21px;
            letter-spacing: 0.8px;
            color: $default-color;
            padding-bottom: 25px;
        }
    }
}

#page-catalog-sale {
    .grid-title {
        border-radius: $base-border-radius;
        background: $default-color;
        color: $white-color;
        margin-bottom: 25px;
        padding: 0 30px;

        .title-left {
            font-weight: 700;
            font-style: normal;
            font-size: 21px;
            letter-spacing: 0.8px;
        }

        .title-right {
            font-weight: 700;
            font-style: normal;
            font-size: 70px;
            text-align: right;

            span {
                color: $primary-color;
            }
        }
    }
}
